@use '~theme/colors' as *;
@use '~theme/typography' as *;
@use '~theme/mixins' as *;

#limit_Offset {
    .modal-content {
        width: 480px;
        border-radius: 8px;

        .modal-header {
            .btn-close {
                font-size: 10px;
            }

            border-top: 0 !important;
        }

        .modal-body {
            .offset-info {
                display: flex;
                padding: 10px 12px;
                background: rgba(0, 81, 255, 0.08);
                border-radius: 8px;
                align-items: center;
                gap: 16px;
                font-family: 'ABBvoice-Regular', sans-serif;
                margin-bottom: 8px;

                p {
                    flex: 1;
                    margin: 0;
                    font-size: 14px;
                    font-weight: 400;
                }

                svg {
                    path {
                        fill: '#0051FF';
                    }
                }
            }

            .offset-input {
                .offset-input-title {
                    margin-bottom: 8px;
                }

                .limit-value-uom {
                    display: flex;
                    margin-bottom: 16px;
                    align-items: center;
                    column-gap: 8px;
                    border-width: 2px;
                    border-color: $alto;
                    border-style: solid;
                    height: 40px;
                    width: 100%;
                    padding: 0 12px;
                    border-radius: 8px;

                    &.highlight {
                        box-shadow: 0px 0px 2px $dodger-blue;
                        border-width: 1px;
                        border-color: $dodger-blue;
                    }

                    input {
                        flex: 1;
                        border: 0;
                        outline: 0;
                        font-size: 14px;
                        color: $dove-gray;
                        font-family: 'ABBvoice-Regular', sans-serif;
                        border-right: 2px solid $alto;
                    }

                    input[type=number]::-webkit-outer-spin-button,
                    input[type=number]::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    .limit-pipe {
                        color: $dove-gray;
                    }

                    .uom-text {
                        font-size: 14px;
                        color: $dove-gray;
                        font-family: 'ABBvoice-Regular', sans-serif;
                        min-width: 40px;
                        text-align: center;
                    }
                }
            }
        }

        .modal-footer {
            .dialog-accept-button {
                border-radius: 32px;
            }
        }
    }
}

.limit-config-container {
    .limit-btn {
        font-family: 'ABBvoice-Medium', sans-serif;
        font-size: 14px;
        font-weight: 500;
    }

    .context-menu .context-menu-list .context-menu-list-item,
    .context-menu .context-menu-list .context-menu-list-item-disabled {
        height: unset;
    }

    .limit-config-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0px;
        flex-wrap: wrap;
        row-gap: 10px;

        .header-left,
        .header-right {
            display: flex;
            align-items: center;
            column-gap: 8px;
        }

        .limit-search-box {
            border-radius: 4px;
            // border: 2px solid $alto;
        }

        .limit-count {
            font-family: 'ABBvoice-Regular', sans-serif;
            color: $dove-gray;
        }

        .limit-config-save-button {
            &.disabled {
                background-color: $primary-disabled !important;
                color: $white !important;
            }
        }

        .limit-back-btn {
            border: 0px !important;
        }
    }

    .signal-limit-container {
        // ADDED FOR MOVING UNDER OPV2
        overflow: auto;
        max-height: calc(var(--vh) * 100 - 340px);

        button[role=expandable-header].header {
            background-color: $black-transparent !important;
        }
        button[role=expandable-header].expanded {
            background-color: $clicked !important;
        }
        .expandable-component {
            border-bottom: 1px solid $silver;
            button.header {
                background-color: $gallery;
            }
            .expandable-content {
                overflow: unset;
            }
        }

        .content-container {
            border-top: 1px solid $silver;

            .limit-values-container {
                position: relative;
                padding: 4px 0 4px 40px;
                display: flex;
                align-items: center;
                column-gap: 8px;
                // border-bottom: 1px solid rgba(0, 0, 0, 0.12);

                .item-disable {
                    width: 100%;
                    height: 100%;
                    background: transparent;
                    position: absolute;
                    z-index: 9;
                    cursor: not-allowed;
                }

                .limit-left-values {
                    flex: 1;
                    display: flex;
                    column-gap: 16px;
                    align-items: center;

                    .limit-value-fields {
                        display: flex;
                        align-items: center;

                        .general-select-container {
                            width: auto !important;
                        }

                        .general-select-wrapper.has-error {
                            .general-select__control {
                                box-shadow: none !important;
                                border-right-width: 2px !important;
                            }
                        }
                        .general-select__control {
                            width: 80px;
                            border-width: 2px;
                            border-right-width: 0;
                            border-radius: 6px 0 0 6px;
                            border-color: $alto;

                            .general-select__single-value--is-disabled {
                                color: $black;
                            }
                        }

                        .limit-label {
                            .limit-value-uom {
                                border: 2px solid $alto;
                                border-radius: 6px;
                                font-size: 14px;
                                color: $mine-shaft;
                            }
                        }

                        .limit-value-uom {
                            display: flex;
                            align-items: center;
                            column-gap: 8px;
                            border-width: 2px;
                            border-color: $alto;
                            border-style: solid;
                            height: 32px;
                            width: 320px;
                            padding: 0 12px;
                            border-radius: 0 6px 6px 0;

                            &.disabled {
                                background-color: $gallery;

                                .uom-text, input {
                                    color: $black;
                                }
                            }

                            &.highlight {
                                box-shadow: 0px 0px 2px $dodger-blue;
                                border-width: 1px;
                                border-color: $dodger-blue;
                            }

                            input {
                                flex: 1;
                                border: 0;
                                outline: 0;
                                font-size: 14px;
                                color: $dove-gray;
                                font-family: 'ABBvoice-Regular', sans-serif;
                            }

                            input[type=number]::-webkit-outer-spin-button,
                            input[type=number]::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }

                            .limit-pipe {
                                color: $dove-gray;
                            }

                            .uom-text {
                                font-size: 14px;
                                color: $dove-gray;
                                font-family: 'ABBvoice-Regular', sans-serif;
                                min-width: 40px;
                                text-align: center;
                                border-left: 2px solid $alto;
                            }
                        }

                        .limit-value-uom.error {
                            border-color: $pomegranate;
                            box-shadow: none;
                            border-width: 2px;
                        }
                    }
                }

                .limit-right-values {
                    min-width: 50px;
                }
            }

            .limit-slider-container {
                padding: 50px 40px 8px 48px;
                border-top: 1px solid rgba(0, 0, 0, 0.12);
            }

            .limit-level-container {
                min-width: 155px;
                padding: 4px;
                background-color: rgba(0, 0, 0, 0.04);
                border-radius: 8px;

                &.disabled {
                    background-color: unset;
                }

                .toggle-button-container {
                    column-gap: 8px;
                    color: $nero;
                    font-size: 14px;
                    font-weight: 500;

                    button {
                        border-radius: 4px;
                        background: transparent;
                        border: 0;
                        min-width: 70px;
                        padding: 4px !important;
                        font-weight: 500;
                        font-family: 'ABBvoice-Medium', sans-serif;
                    }

                    button.active {
                        background: rgba(0, 0, 0, 0.24);
                        color: $nero;
                        font-family: 'ABBvoice-Medium', sans-serif;
                        font-weight: 500;
                    }
                }
            }
        }

        .title-content {
            width: 100%;
        }

        .limit-type-container {
            .limit-type {
                padding: 4px 8px 4px 48px;

                .general-select__control {
                    border-radius: 4px;
                    max-width: 400px;
                }

                .general-select__menu {
                    max-width: 400px;
                }
            }

            .no-limits {
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                font-family: 'ABBvoice-Regular', sans-serif;
                border-top: 1px solid rgba(0, 0, 0, 0.12);
                padding: 21px 0;
                color: $dove-gray;
            }
        }

        .limit-title-container {
            display: flex;
            align-items: center;
            flex: 1;

            .limit-title-left {
                flex: 1;
                display: flex;
                align-items: center;
                padding: 8px 16px 8px 24px;

                .limit-signal {
                    font-size: 16px;
                    font-weight: 500;
                    font-family: 'ABBvoice-Medium', sans-serif;
                    color: $nero;
                    display: flex;
                    text-align: left;
                    .info-button-with-online-help .icon_info {
                        margin-bottom: 0;
                    }

                    input {
                        width: 300px !important;
                    }

                    .limit-uom {
                        color: $dove-gray;
                        margin-left: 10px;
                    }

                    .limit-pipe {
                        margin: 0 5px 0 2px;
                        color: rgba(0, 0, 0, 0.12);
                    }
                    .general-select-wrapper {
                        max-width: 340px !important;
                    }
                }

                .general-select-wrapper {
                    max-width: 340px !important;
                }

                .limit-offset {
                    font-size: 16px;
                    font-weight: 400;
                    color: $nero;
                    font-family: 'ABBvoice-Regular', sans-serif;
                }
            }

            .limit-title-right,
            .limit-notify {
                display: flex;
                align-items: center;
            }

            .limit-title-right {
                padding: 8px 16px;
                column-gap: 8px;

                .general-button.discreet:hover {
                    border: 0;
                }

                .limit-discard {
                    background: transparent;
                }

                .limit-cancel,
                .limit-save {
                    font-family: 'ABBvoice-Medium', sans-serif;
                    font-weight: 500;
                }

                .limit-save {
                    margin-right: 8px;
                }

                .add-limit {
                    display: flex;
                    column-gap: 8px;
                    align-items: center;
                    font-weight: 500;
                    font-size: 14px;
                    color: $nero;
                }

                .add-limit-disabled {
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.40);
                }

                .active-notification-count {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .dot {
                        height: 6px;
                        width: 6px;
                        border-radius: 50%;
                        display: inline-block;
                        &.active {
                            background-color: $dodger-blue;
                        }

                        &.inactive {
                            background-color: $dove-gray;
                        }
                    }
                }
            }

            .limit-notify {
                font-size: 14px;
                font-family: 'ABBvoice-Medium', sans-serif;
                font-weight: 500;
                color: $nero;

                span {
                    margin-left: 8px;
                }
            }
        }
    }
    .notification-banner {
        @include unselectable;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        background-color: $silver-transparent;
        padding: 10px 12px;

        .notification-banner-text {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: $nero;
        }

        .notification-icon-container {
            display: flex;
            align-items: center;
        }

        #closeNotifyButton {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    .personal-limit-container {
        .expandable-component {
            button.header {
                background-color: $white !important;
                border-top: 1px solid $alto;
                border-bottom: 0;
                padding: 0 12px 0px 56px;
            }
        }
    }
}

.suggested-limits-modal {
    .modal-dialog {
        width: 600px !important;
    }

    .suggest-limit-header {
        height: 40px;
        display: flex;
        align-items: center;
        border-bottom: 3px solid $black-semi-transparent;

        .header-item {
            display: flex;
            width: 100%;
            color: $dove-gray;
            font-size: 14px;
            font-family: $font-family-medium;

            &.selected {
                border-bottom: 3px solid $dodger-blue;
            }

            .radio-button-label {
                width: 100%;

                .radio-button-label-text {
                    max-width: 100%;
                    color: $dove-gray;
                    font-size: 14px;
                    font-family: $font-family-medium;
                }
            }
        }
    }
    .suggest-limit-item {
        input {
            color: $black !important;
        }
    }
}

.limit-title-container {
    .reset-default-limits {
        .default-info-content {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
        }
    }
}

.general-dialog-container.reset-default-limits {
    .modal-content {
        .modal-body {
            .default-info-content {
                display: flex;
                justify-content: center;
                gap: 8px;
                background-color: #FFA20014;
                margin-bottom: 10px;
                padding: 8px 12px;

                span {
                    font-size: 14px;
                }
            }

            .review-default {
                display: flex;
                align-items: center;
                margin: 16px 0px;

                span {
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            .limit-box-default {
                display: flex;

                .limit-value-fields {
                    display: flex;
                    align-items: center;

                    .general-select__control {
                        width: 80px;
                        border-width: 2px;
                        border-right-width: 0;
                        border-radius: 6px 0 0 6px;
                        border-color: $alto;

                        .general-select__single-value--is-disabled {
                            color: $black;
                        }
                    }

                    .limit-label {
                        .limit-value-uom {
                            border: 2px solid $alto;
                            border-radius: 6px;
                            font-size: 14px;
                            color: $mine-shaft;
                        }
                    }

                    .limit-value-uom {
                        display: flex;
                        align-items: center;
                        column-gap: 8px;
                        border-width: 2px;
                        border-color: $alto;
                        border-style: solid;
                        height: 32px;
                        width: 320px;
                        padding: 0 12px;
                        border-radius: 6px;

                        &.disabled {
                            background-color: $gallery;

                            .uom-text,
                            input {
                                color: $black;
                            }
                        }

                        &.highlight {
                            box-shadow: 0px 0px 2px $dodger-blue;
                            border-width: 1px;
                            border-color: $dodger-blue;
                        }

                        input {
                            flex: 1;
                            border: 0;
                            outline: 0;
                            font-size: 14px;
                            color: $dove-gray;
                            font-family: 'ABBvoice-Regular', sans-serif;
                        }

                        input[type=number]::-webkit-outer-spin-button,
                        input[type=number]::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        .limit-pipe {
                            color: $dove-gray;
                        }

                        .uom-text {
                            font-size: 14px;
                            color: $dove-gray;
                            font-family: 'ABBvoice-Regular', sans-serif;
                            min-width: 40px;
                            text-align: center;
                            border-left: 2px solid $alto;
                        }
                    }

                    .limit-value-uom.error {
                        border-color: $pomegranate;
                        box-shadow: none;
                        border-width: 2px;
                    }
                }

                .limit-level-container {
                    min-width: 64px;
                    padding: 4px;
                    border-radius: 8px;
                    margin-right: 16px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;

                    &.disabled {
                        background-color: unset;
                    }

                    .toggle-button-container {
                        column-gap: 8px;
                        color: $nero;
                        font-size: 14px;
                        font-weight: 500;

                        button {
                            border-radius: 4px;
                            background: transparent;
                            border: 0;
                            min-width: 70px;
                            padding: 4px !important;
                            font-weight: 500;
                            font-family: 'ABBvoice-Medium', sans-serif;
                        }

                        button.active {
                            background: rgba(0, 0, 0, 0.24);
                            color: $nero;
                            font-family: 'ABBvoice-Medium', sans-serif;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}

.general-dialog-container.reset-default-limits {
    .modal-content {
        .modal-header {
            .general-dialog-header {
                .general-dialog-title {
                    font-size: 20px;
                    line-height: 24px;
                }
            }
        }
    }
}

.suggest-limit-item {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 4px 8px;
    border-bottom: 1px solid $alto;
    column-gap: 6px;

    .general-select__control {
        border-width: 2px 2px 2px 0px;
        border-radius: 6px 0 0 6px;
        border-color: $alto;
    }

    .suggest-limit-item-title {
        flex: 1;
    }

    .suggest-limit-item-direction {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 100%;
        border-right: 2px solid $alto;
        border-radius: 4px 0 0 4px;
        background-color: $gallery;
        color: $dove-gray;
        cursor: not-allowed;
    }

    .limit-value-uom {
        display: flex;
        flex: 1;
        align-items: center;
        border-width: 2px;
        border-color: $alto;
        border-style: solid;
        height: 32px;
        border-radius: 6px;
        margin: 0;

        &.disabled {
            background-color: $gallery;
            cursor: not-allowed;
        }

        input {
            flex: 1;
            width: 50%;
            border: 0;
            outline: 0;
            font-size: 14px;
            color: $dove-gray;
            padding: 0px 0px 0px 8px;
            border-right: 2px solid $alto;
            font-family: 'ABBvoice-Regular', sans-serif;

            &:disabled {
                cursor: not-allowed;
            }
        }

        input[type=number]::-webkit-outer-spin-button,
        input[type=number]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .limit-pipe {
            color: $dove-gray;
        }

        .uom-text {
            font-size: 14px;
            color: $dove-gray;
            font-family: 'ABBvoice-Regular', sans-serif;
            min-width: 40px;
            text-align: center;
        }
    }

    .limit-value-uom.error {
        border-color: $pomegranate;
    }

    .flex-1 {
        display: flex;
        flex: 1;
    }
}

.remove-signal-text {
    white-space: pre-line;
}
